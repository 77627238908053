import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import WithLoading from './app/components/shared/WithLoading';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { ReducerStateContant } from "./app/helper/constants/general-const";
import RouteLoader from "./app/components/shared/RouteLoader";



const Layout = WithLoading(React.lazy(() => import("./app/components/shared/Layout")));


const PageNotFoundPage = WithLoading(React.lazy(() => import("./app/components/PageNotFound")));

const AccessDeniedPage = WithLoading(React.lazy(() => import("./app/components/shared/AccessDenied")));

const RequireAuth = (props) => {
    return <> {props.active ? props.children : <Navigate to="/auth/login" replace />} </>;
};


const LoginPage = WithLoading(React.lazy(() => import("./app/components/Login")));

const DashboardPage = WithLoading(React.lazy(() => import("./app/components/Dashboard")));

const CustomerPage = WithLoading(React.lazy(() => import("./app/components/customers/customer")));

const RestaurantPage = WithLoading(React.lazy(() => import("./app/components/customers/restuarant")));

const RolePage = WithLoading(React.lazy(() => import("./app/components/Roles/role")));

const UserAdminPage = WithLoading(React.lazy(() => import("./app/components/UserAdmin/userAdmin")));

const DistributerPage = WithLoading(React.lazy(() => import("./app/components/distributors/distributors")));

const AppRoutes = (props) => {

    const protectedLayout = (
        <>
            {
                <>
                    {

                        props.authorizedAccess == ReducerStateContant.pending ?
                            <RouteLoader />
                            :
                            <RequireAuth active={props.active}>
                                {isMobile ? <MobileView className="device-mob"> <Layout /> </MobileView> : <BrowserView className="device-desk"> <Layout /> </BrowserView>}
                            </RequireAuth>
                    }
                </>

            }
        </>
    );



    return (
        <Routes>

            <Route  element={protectedLayout}>
                <Route index element={<DashboardPage />} />
                <Route  path="/dashboard" element={<DashboardPage />} />
            </Route>

            <Route path="/customer" element={protectedLayout}>
                <Route index element={<CustomerPage />} />
            </Route>

            <Route path="/customer" element={protectedLayout}>
                <Route path="view" element={<RestaurantPage />} />
            </Route>

            <Route path="/role" element={protectedLayout}>
                <Route index element={<RolePage />} />
            </Route>

            <Route path="/useradmin" element={protectedLayout}>
                <Route index element={<UserAdminPage />} />
            </Route>

            <Route path="/distributors" element={protectedLayout}>
                <Route index element={<DistributerPage />} />
            </Route>


            
            <Route path="/auth" >
                <Route path="login" element={<LoginPage />} />
            </Route>

            {/* <Route path="/accessdenied" element={protectedLayout} >
                <Route index element={<AccessDeniedPage />} />
            </Route>
            <Route path="*" element={protectedLayout}>
                <Route path="*" element={<PageNotFoundPage />} />
            </Route> */}


        </Routes>
    )
}

export default AppRoutes
