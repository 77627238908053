import { toast } from "react-toastify";

let options = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

let options2 = {
  position: "bottom-center",
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "light",
}


export function orderToast(message) {
  toast.success(message, options2);
}
export function success(message) {
  toast.success(message, options);
}

export function error(message) {
  toast.error(message, options);
}

export function warn(message) {
  toast.warn(message, options);
}

export function info(message) {
  toast.info(message, options);
}
