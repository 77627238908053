import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall, logoutUser } from '../../../API';
import * as Toast from "../../../components/shared/Toast";

export const getallCustomersACTION = createAsyncThunk(
  'Customers/getallCustomers',
  async (data) => {
    let d = {
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter: {

        ...data.filter
      },
    };
    return apiCall('/manage/customer/getall', 'POST', d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });

  }
);
export const getCustomerACTION = createAsyncThunk(
  "Customers/get",
  async (data) => {
    return apiCall("/manage/customer/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const insertUpdateCustomerAction = createAsyncThunk(
  "Customers/insertUpdate",
  async (data) => {
    return apiCall("/manage/customer/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // Toast.success(response.data.message.message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            };
          } else {
            // Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const customerChangeStatusACTION = createAsyncThunk(
  "Customers/changeStatus",
  async (data) => {
    return apiCall("/manage/customer/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const customerDeleteACTION = createAsyncThunk(
  "Customers/delete",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/manage/customer/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getallRestuarantACTION = createAsyncThunk(
  'Customers/getallRestuarant',
  async (data) => {
    let d = {
      ...data,
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter: {
        ...data.filter,
      },
    };
    return apiCall('/manage/business/getall', 'POST', d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });

  }
);
export const getRestuarantACTION = createAsyncThunk(
  "Customers/getRestuarant",
  async (data) => {
    return apiCall("/manage/business/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getDatabaseACTION = createAsyncThunk(
  "Customers/getdatabase",
  async (data) => {
    return apiCall("/lookup/getvalue", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const insertUpdateRestuarantAction = createAsyncThunk(
  "Customers/insertUpdateRestuarnt",
  async (data) => {
    return apiCall("/manage/business/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            // Toast.success(response.data.message.message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            };
          } else {
            // Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const restuaranatChangeStatusACTION = createAsyncThunk(
  "Customers/changeStatusrestuarant",
  async (data) => {
    return apiCall("/manage/business/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const restuarantDeleteACTION = createAsyncThunk(
  "Customers/deleteRestuarant",
  async (data) => {
    return apiCall("/manage/business/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const genetareSyncCodeACTION = createAsyncThunk(
  "Customers/syncCode",
  async (data) => {
    return apiCall("/manage/business/generatesynccode", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const customerAutoLoginAction = createAsyncThunk(
  "Customers/getautologindetails",
  async (data) => {
    return apiCall("/manage/customer/getautologindetials", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

