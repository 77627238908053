import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall, logoutUser } from '../../../API';
import * as Toast from "../../../components/shared/Toast";

export const getallUserACTION = createAsyncThunk(
    'User/getallUser',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {

                ...data.filter
            },
        };
        return apiCall('/manage/user/getall', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });

    }
);
export const getUserACTION = createAsyncThunk(
    "User/get",
    async (data) => {
        return apiCall("/manage/user/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const insertUpdateUserAction = createAsyncThunk(
    "User/insertUpdate",
    async (data) => {
        return apiCall("/manage/user/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // Toast.success(response.data.message.message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        };
                    } else {
                        // Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const userChangeStatusACTION = createAsyncThunk(
    "User/changeStatus",
    async (data) => {
        return apiCall("/manage/user/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            id: data.id,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const userDeleteACTION = createAsyncThunk(
    "User/delete",
    async (data) => {
        return apiCall("/manage/user/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            data: {
                                ...data,
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const userSetPasswordACTION = createAsyncThunk(
    "User/SetPassword",
    async (data) => {
        return apiCall("/manage/user/setpassword", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            data: {
                                ...data,
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);